import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/SEO/index.js"

import BreadCrumbs from "../components/BreadCrumbs"

const PageTemplate = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="page">
        <BreadCrumbs
          links={[
            {title: "Home"},
            {title: "Pages", link: "/collections/all/1"},
            {title: frontmatter.title }
          ]}
        />
        <h1>{frontmatter.title}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <SEO meta={{
        title: `${frontmatter.title}`,
        description: "Informative page about how SVGRepo works",
        breadCrumbs: [{
          to: `/collections/all/1`,
          title: `Page`
        },
        {
          to: `/page/licensing`,
          title: `Licensing`
        }]
      }} />
    </Layout>
  )
}

export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
